
import { TreeNode } from 'primeng/api/treenode';


/*Changes for TreeTable */
/*
Change1:-Make a private property _Q1_2021_Qty
Change2:-Add property in if(columns[i].ColumnName != "Q1_2021"){
Change3:-Make setter property and pass property Q1_2021 in method 
  set Q1_2021(val: number) {
    //debugger;
        //Check for empty value -TO.DO
        this._Q1_2021_Qty = Number(val);
        // If there is no parent then its a root
        if (!this.parent) {
            this._factorizeChildrens("Q1_2021");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q1_2021");
            } else {
                this._factorizeParentAndChildrensMiddle("Q1_2021");
            }
        }
    }
Change4:-Make getter property 
    get Q1_2021(): number {
        return this._Q1_2021;
    }
*/  
//Q1_2021,Q1_2022,Q2_2020,Q2_2021,Q3_2020,Q3_2021,Q4_2020,Q4_2021
export class FileSystemNode implements TreeNode {
/*Change1*/ 
    _Q1_2021_Qty: number = 0;
    _Q1_2022_Qty: number = 0;
    _Q2_2020_Qty: number = 0;
    _Q2_2021_Qty: number = 0;
    _Q3_2020_Qty: number = 0;
    _Q3_2021_Qty: number = 0;
    _Q3_2022_Qty: number = 0;
    _Q4_2022_Qty: number = 0;
    _Q4_2020_Qty: number = 0;
    _Q4_2021_Qty: number = 0;
    _Q2_2022_Qty: number = 0;
    _Q1_2023_Qty: number = 0;
    _Q2_2023_Qty: number = 0;

    _Q1_2021_Wgt: number = 0;
    _Q1_2022_Wgt: number = 0;
    _Q2_2020_Wgt: number = 0;
    _Q2_2021_Wgt: number = 0;
    _Q3_2020_Wgt: number = 0;
    _Q3_2022_Wgt: number = 0;
    _Q4_2022_Wgt: number = 0;
    _Q3_2021_Wgt: number = 0;
    _Q4_2020_Wgt: number = 0;
    _Q4_2021_Wgt: number = 0;
    _Q2_2022_Wgt: number = 0;
    _Q1_2023_Wgt: number = 0;
    _Q2_2023_Wgt: number = 0;
    _InitalValueQ1_2021: number =0;
    _ratio: number = 100;
    data?: FileSystemNode;
    children?: FileSystemNode[];
    parent?: TreeNode;
    leaf: boolean;
    type: string;
    key: string;
    dirtyCheck:boolean;
    columnList:string[];
    ratio1: {};
    filterParent: boolean = false;
    
    Updated: boolean = false;

    constructor(data?: any, parent?: TreeNode, columns?: any[], filter?: TreeNode) {
        this.data = data;
        this.columnList = [];//List of editable columns
        //  this.filterParent=filter;

        if (parent) {
            this.parent = <FileSystemNode>parent;
        }


        /*set each node values on filter */
        if (filter) {
            this.filterParent = true;
            this.parent = filter["node"];
            if (filter["node"].children.length > 0) {
                var filteredChildren = filter["node"].children;
                filteredChildren.forEach(filteredChild => {
                    columns.forEach(column => {
                        this[column.ColumnName] = filteredChild.data[column.ColumnName];
                    })

                });
            }
        }

        if (!data) return;

        let queue = [data];
        let nodeQueue: Array<TreeNode> = [this];

        while (queue.length > 0) {
            let dataNode = queue.shift();
            dataNode.visited = true; // Marking node as visited

            let node = nodeQueue.shift();
            node.data = new FileSystemNode();
            this.columnList = [];
            columns.forEach(column => {

                node.data[column.ColumnName] = dataNode.data[column.ColumnName];
                var _columnName = "_" + column.ColumnName;
                //node.data[_columnName]=dataNode.data[column.ColumnName];
                this.columnList.push(column.ColumnName);

            })

            node.data.dirtyCheck = false;
            //  node.data["Updated"]=false;
            node.data.Updated = false
            node.data.ratio1 = {};
            node.data.style = {};
            if (node.parent) {
                node.data.parent = node.parent;
                node.data.parent.subtotal = {};
                columns.forEach(column => {
                if(column.ColumnName!="Q1_2021_Qty" || column.ColumnName!="Q1_2022_Qty" ||
                column.ColumnName!="Q2_2020_Qty" || column.ColumnName!="Q2_2021_Qty" ||
                column.ColumnName!="Q3_2020_Qty" || column.ColumnName!="Q3_2021_Qty" ||
                column.ColumnName!="Q4_2020_Qty" || column.ColumnName!="Q4_2021_Qty" || column.ColumnName!="Q2_2022_Qty"
                ||column.ColumnName!="Q3_2022_Qty" ||column.ColumnName!="Q4_2022_Qty" || column.ColumnName!="Q1_2023_Qty"||column.ColumnName!="Q2_2023_Qty"
                )// if(column.InputControls!="TextBox")
                 {
                   ////console.log("Value -",(dataNode.data[column.ColumnName]),"-Parent -",node.data.parent.data[column.ColumnName])
                   let nodeRatio: number = ((dataNode.data[column.ColumnName]) / node.data.parent.data[column.ColumnName]);
                   ////console.log("Ratio",nodeRatio);
                   node.data.ratio1[column.ColumnName] = Number.isNaN(nodeRatio) ? 0 : nodeRatio;
                   node.data.style[column.ColumnName] = {};
                   node.data.style[column.ColumnName].color = "black";
                }
            });

            node.parent = null;
            //node.data._ratio = (dataNode.data.Q1_2021 / node.data.parent.data.Q1_2021) * 100;
        } else {
            columns.forEach(column => {
                node.data.ratio1[column.ColumnName] = 1;

            });
            //node.data._ratio = 100;
        }

        

        node.data._prevRatio = node.data.ratio;
        if (!dataNode.children) continue;
        node.data.children = [];
        node.children = [];
        for (let i = 0; i < dataNode.children.length; i++) {
            //if (!dataNode.children[i].visited) {
            queue.push(dataNode.children[i]);
            let child = new FileSystemNode(null, node);
            node.children.push(child);
            node.data.children.push(child); // Added this for root node children reference.
            nodeQueue.push(node.children[i]);
            //}
        }
    }
}
   

_factorizeChildrens(ColumnName?: any) {
        
    debugger;
    // Distributing the values according to the existing child proportion
    // Distributing the values to the child nodes recursively using BFS.
    if (typeof this.children != undefined && this.children != undefined) {
        for (let i = 0; i < this.children.length; i++) {
            let queue = [this.children[i]];
            while (queue.length > 0) {
                let node = <FileSystemNode>queue.shift();
                if (!node.data) continue;
                // Updating the child node value
                if (node.parent) {
                    // Added by sagar
                    let sum: number = 0;
                    //////console.log(node.children);
                    let total: number = node.parent.children.reduce((sum, n) => {
                        sum = (sum ) + (n.data[ColumnName]);
                        return sum;
                    }, sum);
                    /// till here
                    if(node.parent.data["_" + ColumnName] != (total))
                        node.parent.data.Updated=true;
                    node.data["_" + ColumnName] = (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.parent.data)[ColumnName];
                    node.data["_" + ColumnName] = (node.data["_" + ColumnName]);
                    ////console.log(node.data["_" + ColumnName], ",", node.data.ratio1[ColumnName]);
                } else if (node.data.parent) {
                    if(node.data["_" + ColumnName] != (node.data["_" + ColumnName]))
                    node.data.parent.data.Updated=true;
                    node.data["_" + ColumnName] = (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.data.parent.data)[ColumnName];
                    node.data["_" + ColumnName] = (node.data["_" + ColumnName] );
                }

                if (!node.data.children) continue;
                for (let j = 0; j < node.data.children.length; j++) {
                    //if (!(<FileSystemNode>node.data.children[j])._visited) {
                    queue.push(node.data.children[j]);
                    //}
                }
            }
        }
    }
}


_factorizeParentAndChildrens(ColumnName?: any) {
    debugger;
    console.log("_factorizeParentAndChildrens");
    let parent = this.parent;
    let queue = [this.parent];
    while (queue.length > 0) {
        let node = <FileSystemNode>queue.shift();
        if (!node.children) continue;
        let sum: number = 0;
        let total: number = node.children.reduce((sum, n) => {
            sum = (sum) + (n.data[ColumnName]);
            return sum;
        }, sum);

        if (node.data["_" + ColumnName] != (total)&& this.filterParent==false)
            node.data.Updated = true;
        node.data["_" + ColumnName] = (total);

        if (this.filterParent == true) {
            if (node.parent) {
                parent = node.parent;

                queue.push(node.parent);
            }
        }
        else {
            if (node.data.parent) {
                parent = node.data.parent;
                queue.push(node.data.parent)
            }
        }
    }

    // Update the ratios of the child nodes recursively 
    if (parent.children && parent.children.length > 0) {
        for (let i = 0; i < parent.children.length; i++) {
            queue = [parent.children[i]];
            let flag: number = 0;
            let total: number;
            while (queue.length > 0) {
                let node = <FileSystemNode>queue.shift();

                //node.data.ratio1[ColumnName] = (node.data._Consensus / node.parent.data.Consensus) * 100;
                node.data.ratio1[ColumnName] = (node.data["_"+ColumnName] / node.parent.data[ColumnName]) ;

                if (!node.data.children) continue;
                for (let j = 0; j < node.data.children.length; j++) {
                    queue.push(node.data.children[j]);
                }
            }
        }
    }
}

_factorizeParentAndChildrensMiddle(ColumnName?: any) {
    debugger;
    console.log("_factorizeParentAndChildrensMiddle");
    let parent = this.parent;
    
    
    let queue = [this.parent];
    while (queue.length > 0) {
        let node = <FileSystemNode>queue.shift();
        if (!node.children) continue;
        let sum: number = 0;
        //////console.log(node.children);
        let total: number = node.children.reduce((sum, n) => {
            sum = (sum) + (n.data[ColumnName]);
            return sum;
        }, sum);
        if (node.data["_" + ColumnName] != (total))
            node.data.Updated = true;
        node.data["_" + ColumnName] = (total);


        node.data.dirtyCheck = true;
        if (this.filterParent == true) {
            if (node.parent) {
                parent = node.parent;
                queue.push(node.parent);
            }
        }
        else {
            
            if (node.data.parent) {
                parent = node.data.parent;
                queue.push(node.data.parent)
            }
        }

    }

    for (let i = 0; i < this.children.length; i++) {
        debugger;
        let queue = [this.children[i]];
        while (queue.length > 0) {
            let node = <FileSystemNode>queue.shift();
            if (!node.data) continue;
            // Updating the child node value
           // if (isNaN(node.data.ratio1[ColumnName])) node.data.ratio1[ColumnName] = 0; //node.data._prevRatio;
            if (node.parent) {
                if(node.data["_" + ColumnName] != (node.data["_" + ColumnName]))
                    node.parent.data.Updated=true;
                //if((<FileSystemNode>node.parent.data)[ColumnName]!=0)
                    node.data["_" + ColumnName] = (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.parent.data)[ColumnName];
                
                if(node.data["_" + ColumnName]>=1)
                    node.data["_" + ColumnName] = (node.data["_" + ColumnName]);
                else
                    node.data["_" + ColumnName] = (node.data["_" + ColumnName]);


            } else if (node.data.parent) {
                if(node.data["_" + ColumnName] != (node.data["_" + ColumnName]))
                    node.data.parent.data.Updated=true;
                //if((<FileSystemNode>node.parent.data)[ColumnName]!=0)
                    node.data["_" + ColumnName] =    (node.data.ratio1[ColumnName]) * (<FileSystemNode>node.data.parent.data)[ColumnName];
                node.data["_" + ColumnName] = (node.data["_" + ColumnName]);
            }

            if (!node.data.children) continue;
            for (let j = 0; j < node.data.children.length; j++) {
                //if (!(<FileSystemNode>node.data.children[j])._visited) {
                queue.push(node.data.children[j]);
                //}
            }
        }
    }

    // Update the ratios of the child nodes recursively 
    if (parent.children && parent.children.length > 0) {
        for (let i = 0; i < parent.children.length; i++) {
            queue = [parent.children[i]];
            
            let flag: number = 0;
            let total: number;
            while (queue.length > 0) {
                let node = <FileSystemNode>queue.shift();
                if (!node.parent) continue;

                  // console.log("EQUAL");
                //  console.log("Value",node.data["_"+ColumnName],"-parent",node.parent.data[ColumnName]);
                // console.log("Ratio previous",node.data.ratio1[ColumnName]);
                let ratio:number = (node.data["_"+ColumnName] / node.parent.data[ColumnName]);
                // console.log("NewRatio",ratio);
                if(!isNaN(ratio))//don't calculate ratio when NaN
                    node.data.ratio1[ColumnName]=ratio;

                if (!node.data.children) continue;
                for (let j = 0; j < node.data.children.length; j++) {
                    queue.push(node.data.children[j]);
                }
            }
        }
    }
}

    /*Change3 */
    set Q1_2021_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q1_2021_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q1_2021_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q1_2021_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q1_2021_Qty");
            }
        }
    }
    }

    get Q1_2021_Qty(): number {
        return this._Q1_2021_Qty;
    }

    set Q1_2022_Qty(val: number) {
       //Check for empty value -TO.DO
       this._Q1_2022_Qty = Number(val);
       if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
           this.dirtyCheck = true;
       // If there is no parent then its a root
       if (!this.parent) {
           if(this.children != undefined)
               this._factorizeChildrens("Q1_2022_Qty");
       } else {
           // If there is no children then it is a leaf node, then update its parent values recursively.
           if (!this.children) {
               this._factorizeParentAndChildrens("Q1_2022_Qty");
           } else {
               this._factorizeParentAndChildrensMiddle("Q1_2022_Qty");
           }
       }
   }
    }

    get Q1_2022_Qty(): number {
        return this._Q1_2022_Qty;
    }

    set Q1_2023_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q1_2023_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;

            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q1_2023_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q1_2023_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q1_2023_Qty");
            }
        }
    }
     }
 
     get Q1_2023_Qty(): number {
         return this._Q1_2023_Qty;
     }
     set Q2_2023_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q2_2023_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q2_2023_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q2_2023_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q2_2023_Qty");
            }
        }
    }
     }
 
     get Q2_2023_Qty(): number {
         return this._Q2_2023_Qty;
     }



    set Q2_2022_Qty(val: number) {
       //Check for empty value -TO.DO
       this._Q2_2022_Qty = Number(val);
       if(!isNaN(Number(val)) && Number(val)  != 0){
        if (!this.children)
            this.Updated = true;
        
           this.dirtyCheck = true;
       // If there is no parent then its a root
       if (!this.parent) {
           if(this.children != undefined)
               this._factorizeChildrens("Q2_2022_Qty");
       } else {
           // If there is no children then it is a leaf node, then update its parent values recursively.
           if (!this.children) {
               this._factorizeParentAndChildrens("Q2_2022_Qty");
           } else {
               this._factorizeParentAndChildrensMiddle("Q2_2022_Qty");
           }
       }
   }
    }

    get Q2_2022_Qty(): number {
        return this._Q2_2022_Qty;
    }

    set Q2_2020_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q2_2020_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q2_2020_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q2_2020_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q2_2020_Qty");
            }
        }
    }
    }
 
    get Q2_2020_Qty(): number {
         return this._Q2_2020_Qty;
    }

    set Q2_2021_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q2_2021_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q2_2021_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q2_2021_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q2_2021_Qty");
            }
        }
    }
    }
 
    get Q2_2021_Qty(): number {
         return this._Q2_2021_Qty;
    }

    set Q3_2020_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q3_2020_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q3_2020_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q3_2020_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q3_2020_Qty");
            }
        }
    }
    }
 
    get Q3_2020_Qty(): number {
         return this._Q3_2020_Qty;
    }

    set Q3_2021_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q3_2021_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q3_2021_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q3_2021_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q3_2021_Qty");
            }
        }
    }
    }
 
    get Q3_2021_Qty(): number {
         return this._Q3_2021_Qty;
    }

    set Q4_2020_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q4_2020_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q4_2020_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q4_2020_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q4_2020_Qty");
            }
        }
    }
    }
 
    get Q4_2020_Qty(): number {
         return this._Q4_2020_Qty;
    }

    set Q4_2021_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q4_2021_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q4_2021_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q4_2021_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q4_2021_Qty");
            }
        }
    }
    }
 
    get Q4_2021_Qty(): number {
         return this._Q4_2021_Qty;
    }

    set Q3_2022_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q3_2022_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q3_2022_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q3_2022_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q3_2022_Qty");
            }
        }
    }
    }
 

    get Q3_2022_Qty(): number {
        return this._Q3_2022_Qty;
    }

    set Q4_2022_Qty(val: number) {
        //Check for empty value -TO.DO
        this._Q4_2022_Qty = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q4_2022_Qty");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q4_2022_Qty");
            } else {
                this._factorizeParentAndChildrensMiddle("Q4_2022_Qty");
            }
        }
    }
    }

    get Q4_2022_Qty(): number {
        return this._Q4_2022_Qty;
    }



    /********************Tons*********************** */
     /*Change3 */
     set Q1_2021_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q1_2021_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q1_2021_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q1_2021_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q1_2021_Wgt");
            }
        }
    }
    }

    get Q1_2021_Wgt(): number {
        return this._Q1_2021_Wgt;
    }

    set Q1_2023_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q1_2023_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q1_2023_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q1_2023_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q1_2023_Wgt");
            }
        }
    }
    }

    get Q1_2023_Wgt(): number {
        return this._Q1_2023_Wgt;
    }

    set Q1_2022_Wgt(val: number) {
       //Check for empty value -TO.DO
       this._Q1_2022_Wgt = Number(val);
       if(!isNaN(Number(val)) && Number(val)  != 0){
        if (!this.children)
            this.Updated = true;
           this.dirtyCheck = true;
       // If there is no parent then its a root
       if (!this.parent) {
           if(this.children != undefined)
               this._factorizeChildrens("Q1_2022_Wgt");
       } else {
           // If there is no children then it is a leaf node, then update its parent values recursively.
           if (!this.children) {
               this._factorizeParentAndChildrens("Q1_2022_Wgt");
           } else {
               this._factorizeParentAndChildrensMiddle("Q1_2022_Wgt");
           }
       }
   }
    }

    get Q1_2022_Wgt(): number {
        return this._Q1_2022_Wgt;
    }

    set Q2_2022_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q2_2022_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                 this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q2_2022_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q2_2022_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q2_2022_Wgt");
            }
        }
    }
     }
 
     get Q2_2022_Wgt(): number {
         return this._Q2_2022_Wgt;
     }
    set Q2_2020_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q2_2020_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q2_2020_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q2_2020_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q2_2020_Wgt");
            }
        }
    }
    }
 
    get Q2_2020_Wgt(): number {
         return this._Q2_2020_Wgt;
    }

    set Q2_2021_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q2_2021_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q2_2021_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q2_2021_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q2_2021_Wgt");
            }
        }
    }
    }
 
    get Q2_2021_Wgt(): number {
         return this._Q2_2021_Wgt;
    }

    set Q3_2020_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q3_2020_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;

            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q3_2020_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q3_2020_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q3_2020_Wgt");
            }
        }
    }
    }
 
    get Q3_2020_Wgt(): number {
         return this._Q3_2020_Wgt;
    }

    set Q3_2021_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q3_2021_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q3_2021_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q3_2021_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q3_2021_Wgt");
            }
        }
    }
    }
 
    get Q3_2021_Wgt(): number {
         return this._Q3_2021_Wgt;
    }

    set Q4_2020_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q4_2020_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q4_2020_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q4_2020_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q4_2020_Wgt");
            }
        }
    }
    }
 
    get Q4_2020_Wgt(): number {
         return this._Q4_2020_Wgt;
    }

    set Q4_2021_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q4_2021_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q4_2021_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q4_2021_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q4_2021_Wgt");
            }
        }
    }
    }
 
    get Q4_2021_Wgt(): number {
         return this._Q4_2021_Wgt;
    }


    set Q3_2022_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q3_2022_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q3_2022_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q3_2022_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q3_2022_Wgt");
            }
        }
    }
    }
 
    get Q3_2022_Wgt(): number {
         return this._Q3_2022_Wgt;
    }

    
    set Q4_2022_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q4_2022_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q4_2022_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q4_2022_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q4_2022_Wgt");
            }
        }
    }
    }
 
    get Q4_2022_Wgt(): number {
         return this._Q4_2022_Wgt;
    }

    set Q2_2023_Wgt(val: number) {
        //Check for empty value -TO.DO
        this._Q2_2023_Wgt = Number(val);
        if(!isNaN(Number(val)) && Number(val)  != 0){
            if (!this.children)
                this.Updated = true;
            this.dirtyCheck = true;
        // If there is no parent then its a root
        if (!this.parent) {
            if(this.children != undefined)
                this._factorizeChildrens("Q2_2023_Wgt");
        } else {
            // If there is no children then it is a leaf node, then update its parent values recursively.
            if (!this.children) {
                this._factorizeParentAndChildrens("Q2_2023_Wgt");
            } else {
                this._factorizeParentAndChildrensMiddle("Q2_2023_Wgt");
            }
        }
    }
    }

    get Q2_2023_Wgt(): number {
        return this._Q2_2023_Wgt;
    }





    get ratio(): number {
        return this._ratio;
       // return Number(this._ratio.toFixed(2));
    }

    set ratio(ratio: number) {
        this._ratio = ratio;
    }

}

     
