import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { MenuPage } from './menu.page';
import { MainpagePageModule } from '../mainpage/mainpage.module';


const routes: Routes = [
  {
        path: '',
        component: MenuPage,
        children: [
          {
            path: 'first',
            loadChildren: ()=>MainpagePageModule
        },
            {
                path: 'profile',
                loadChildren: '../profile/profile.module#ProfilePageModule'
            }
        ]
    }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MenuPage]
})
export class MenuPageModule {}
