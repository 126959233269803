import { Routes } from '@angular/router';
import { MainpagePage } from './mainpage.page';
import {Menu4Page} from './menu4/menu4.component';
import {Menu5Page} from './menu5/menu5.component';
import {Menu7Page} from './menu7/menu7.component';
import {Menu8Page} from './menu8/menu8.component';
import {Menu9Page} from './menu9/menu9.component';
import {Menu10Page} from './menu10/menu10.component';
import {Menu11Page} from './menu11/menu11.component';
import {Menu12Page} from './menu12/menu12.component';
import {Menu13Page} from './menu13/menu13.component';
import {Menu14Page} from './menu14/menu14.component';
import {Menu15Page} from './menu15/menu15.component';
import {Menu16Page} from './menu16/menu16.component';
import {Menu17Page} from './menu17/menu17.component';
import {Menu18Page} from './menu18/menu18.component';
import {Menu20Page} from './menu20/menu20.component';
import {Menu22Page} from './menu22/menu22.component';
import {Menu23Page} from './menu23/menu23.component';
import {Menu24Page} from './menu24/menu24.component';
import {Menu25Page} from './menu25/menu25.component';
import {Menu26Page} from './menu26/menu26.component';
import {Menu27Page} from './menu27/menu27.component';
import {Menu21Page} from './menu21/menu21.component';
import {Menu28Page} from './menu28/menu28.component';
import {Menu29Page} from './menu29/menu29.component';
import {Menu33Page} from './menu33/menu33.component';
import {Menu34Page} from './menu34/menu34.component';
import {Menu35Page} from './menu35/menu35.component';
import {Menu36Page} from './menu36/menu36.component';
import {Menu37Page} from './menu37/menu37.component';
import {Menu38Page} from './menu38/menu38.component';
import {Menu39Page} from './menu39/menu39.component';
import {Menu2Page} from './menu2/menu2.component';
export const routes: Routes = [
{
 path: 'tabs',
 component: MainpagePage,
children: [
{
path: '4',
component: Menu4Page
},
{
    path: '5',
    component: Menu5Page
    },
{
path: '7',
component: Menu7Page
},
{
path: '8',
component: Menu8Page
},
{
path: '9',
component: Menu9Page
},
{
path: '10',
component: Menu10Page
},
{
path: '11',
component: Menu11Page
},
{
path: '12',
component: Menu12Page
},
{
path: '13',
component: Menu13Page
},
{
path: '14',
component: Menu14Page
},
{
path: '15',
component: Menu15Page
},
{
    path: '16',
    component: Menu16Page
 },
 {
     path: '17',
     component: Menu17Page
},
 {
     path: '18',
     component: Menu18Page
},
{
    path: '20',
    component: Menu20Page
    },
{
    path: '22',
    component: Menu22Page
},
{
    path: '21',
    component: Menu21Page
},
{
    path: '23',
    component: Menu23Page
},
{
    path: '24',
    component: Menu24Page
},
{
    path: '25',
    component: Menu25Page
},
{
    path: '26',
    component: Menu26Page
},
{
    path: '27',
    component: Menu27Page
},
{
    path: '28',
    component: Menu28Page
},
{
    path: '29',
    component: Menu29Page
},
{
    path: '33',
    component: Menu33Page
},
{
    path: '34',
    component: Menu34Page
},
{
    path: '35',
    component: Menu35Page
},
{
    path: '36',
    component: Menu36Page
},
{
    path: '37',
    component: Menu37Page
},
{
    path: '38',
    component: Menu38Page
},
{
    path: '39',
    component: Menu39Page
},
{
    path: '2',
    component: Menu2Page
},
 ]
}
];
