import { Injectable,OnInit } from '@angular/core';
import { MainPageService } from '../Services/MainPage.service';

@Injectable({
  providedIn: 'root'
})
export class CustomService implements OnInit {
  
  public toggle:boolean;
  DDListvalues1:any= [];
  DDListvalues:any = [];
  UserName: string;
  constructor(private mainpageservice: MainPageService) { 
    
    this.toggle=true;
   }
   
  

    onValChange(value){
      debugger;
        if(value=='Yes')
        {
            this.toggle=true;
        }
        else
        {
            this.toggle=false;
        }
        
   }
   
   isToggle():boolean
   {
     ///debugger;
     return this.toggle;
   }

   GetNoOfTrucksTextBoxValue = function(moduledetailid, ddid,value){
    debugger;
    var ddl1name = moduledetailid;
      var ddl1value = value;
    let ddlExists:boolean = false ; 
    
    let Quantity:number=0;
    let Capacity:number =0;
    var length1 = this.DDListvalues1.length;
    debugger;
    for ( var i=0;  i < this.DDListvalues1.length ; i++)   {
         if (this.DDListvalues1[i].id == ddl1name) {
        this.DDListvalues1[i].value = ddl1value;
        ddlExists = true ;
        break; }    
    }


    if (ddlExists == false ) {
        this.DDListvalues1.push({id:ddl1name, value:ddl1value}) ;
    }
  
    if (this.DDListvalues1.length > 1)
    {
          if (this.DDListvalues1[0].id < this.DDListvalues1[1].id)    {
              Capacity = this.DDListvalues1[0].value ; 
                  Quantity = this.DDListvalues1[1].value ;      }
          else     {
              Quantity = this.DDListvalues1[0].value ; 
              Capacity = this.DDListvalues1[1].value ;   
          }
    }
    var NoOfTruck  = Math.round(Quantity/Capacity);
  
    if((NoOfTruck*Capacity) < Quantity)   {
         NoOfTruck = NoOfTruck + 1;
    }
    $(document.getElementsByName(ddid)).val(NoOfTruck);

}

GetNoOfTrucksEditASN = function(VACapacity, ddid, Value){
  debugger;
  let Quantity:number=0;
  let Capacity:number =0;
  Quantity=Value;
  //let VehicleAxleCapacity:any =  $(document.getElementsByName(moduledetailid));
   //Capacity = VehicleAxleCapacity[0].value;
 Capacity = VACapacity;
  var NoOfTruck  = Math.round(Quantity/Capacity);
  if((NoOfTruck*Capacity) < Quantity)   {
       NoOfTruck = NoOfTruck + 1;
  }
  $(document.getElementsByName(ddid)).val(NoOfTruck);

}


GetTotalPrice = function(ddid, dependentddid, ddlValue){
  //PerVehicleRate
  debugger;
  let Price =0;
       // let PerVehicleRate:any=0;
    
        let Margin =0;
        let PerVehicleRate:any = document.getElementById(ddid);
        let rate = PerVehicleRate.value;
        //let PerVehicleRate1:any = document.getElementsByName(ddid);
        //let rate1 = PerVehicleRate1[0].value;
        
       // let PerVehicleRate1:any = document.getElementsByName(dependentddid);
        //let rate1 = PerVehicleRate1[0].value;
        Margin=parseInt(ddlValue);
        Price = parseInt(rate) + Margin;
        $(document.getElementsByName(dependentddid)).val(Price);
  
  

}

GetDependentTextBoxValue = function(moduledetailid, dependentDDID, ddlValue)
     {
        var ddl1name = moduledetailid;
        var ddl1value = ddlValue;
        var ddlExists = false ; 
        var Param1 = '';
        var Param2 = '';
        var DDListvalues = [];
       // var username = sessionStorage.getItem('ses-UserName')
       debugger;
        for (var i = 0; i < this.DDListvalues.length; i++) {
                if (this.DDListvalues[i].id == ddl1name) {
                    this.DDListvalues[i].value = ddl1value;
                    ddlExists = true ;
                    break;
                }    
        }
            
       if (ddlExists == false ) {
            this.DDListvalues.push({id:ddl1name, value:ddl1value}) ;
        }
       // Param1 = ddlValue;
        if(this.DDListvalues.length == 1)
        {
          Param1 = this.DDListvalues[0].value;
        }
        else
        {    
             if (this.DDListvalues[0].id < this.DDListvalues[1].id) {
                  Param1 = this.DDListvalues[0].value ; 
                  Param2 = this.DDListvalues[1].value ;   
              }
              else {
                  Param2 = this.DDListvalues[0].value ; 
                  Param1 = this.DDListvalues[1].value ;   
              }
        }
        this.UserName = localStorage.getItem('username');
        this.mainpageservice.GetDependentTextBoxValue(moduledetailid, Param1, Param2, this.UserName).subscribe(response => 
            {
                console.log(response);
               // var textBoxName = response.Table[0].Text ;
                //var textBoxValue = response.Table[0].Value ;

                //$(document.getElementsByName(textBoxName)).val(textBoxValue);
                debugger;
                let dataArray: Array<any> = [];
                dataArray = response.Table[0];
                for(let item in dataArray)
                {
                   // var item1 = item;
                    var value = dataArray[item];
                    $(document.getElementsByName(item)).val(value);
                }
            })  
         
       }
    

   ngOnInit()
   {
    this.UserName = localStorage.getItem('username');
   }
}
