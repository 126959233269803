import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../Services/authentication.service';
import { LoginService } from '../Services/Login.service';
import { IUser } from '../Models/User';
import { Events } from '@ionic/angular';
import { Data } from "../Services/data.service";
import { error } from 'protractor';
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
    public User: IUser;
    public error: string;
    public show: boolean = false;
    public showloader: boolean = false;    //public file:any;
    hideshow:any = false;
    showfn(){
      this.hideshow=!this.hideshow;
    }

    constructor(private authentication: AuthenticationService, private loginservice: LoginService,
        public events: Events,
        public data: Data) {
        this.events.publish('PageName', 'login');}

  ngOnInit() {
    this.showloader=true;
    this.show=false;
    setTimeout(() => {
    console.log('Welcome to Datasavi');
    this.showloader=false;

      }, 8000);
  }

    loginUser(e) {
        this.show=true;
        e.preventDefault();
        console.log(e);
        var username = e.target.elements[0].value;
        var password = e.target.elements[1].value;
       
        this.loginservice.getUserDetails(username, password)
            .subscribe(data => {
            this.User = data;
            console.log(this.User);
            if (this.User.Error != null) {
                this.error = this.User.Error;
                this.show=false;

            }
            else {
                this.data.storage = {
                    "firstname": "Nic",
                    "lastname": "Raboy",
                    "address": {
                        "city": "San Francisco",
                        "state": "California"
                    }
                }
                this.authentication.login(username, password);
                this.show=false;

            }
            },
            error => {
                this.error = error;
            })
        


    }

    public type = 'password';
    public showPass = false;
      showPassword() {
      this.showPass = !this.showPass;
      if(this.showPass){
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    }
    public emailsubmitted:boolean= false;
    forgotpassword(e:any){
      var email:any = e.target.elements[0].value;
      this.loginservice.forgotPassword(email).subscribe(data=>{
        console.log(data);
        this.emailsubmitted = true;
      });
    }
}
