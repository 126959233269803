import { Component, OnInit, Type, NgModuleFactory, Compiler } from '@angular/core';
import { ActivatedRoute, Routes, Route } from '@angular/router';
import { MenuService } from '../Services/menu.service';
import { Router } from '@angular/router';
//import { Page1Component } from './page1/page1.component';

import { Events } from '@ionic/angular';
import { Observable } from 'rxjs';
@Component({
    selector: 'app-mainpage',
    templateUrl: './mainpage.page.html',
    styleUrls: ['./mainpage.page.scss'],
})
export class MainpagePage implements OnInit {
    UserName: string;
    ID: number;
    SubMenu: any;
    isCacheUser :any;
    //MenuId: number=1;
    constructor(private activatedroute: ActivatedRoute, private menuservice: MenuService,
        private router: Router, public events: Events, private _compiler: Compiler) {
        console.log('beforactive', activatedroute.routeConfig);
        //this.menuservice.getJSON().subscribe(data => {
        //    let jsondata= JSON.parse(data);
        //    let routes = <Routes>[];
        //    jsondata.forEach(route => {
        //        let ro1: Route = {};
        //        ro1.path = route.path;
        //        ro1.component = route.component as Type<Menu1Page>;
        //        ro1.loadChildren = './amainpage/sample/sample.module#SamplePageModule';
        //        console.log('myroutes', ro1);
        //        routes.unshift(ro1);
        //    });
           
        //    activatedroute.routeConfig.children = routes;
        //    console.log('activeroutes', activatedroute.routeConfig);
        //    this.router.resetConfig(config);  
        //});

        //activatedroute.routeConfig.children = [
        //    {
        //        path: "page1", component: Page1Component 
        //    },
        //    {
        //        path: "1", component: Menu1Page 
        //    }
        //];
        //this.router.config.unshift(
        //    { path: 'menu/first/tabs/page1', component: Page1Component }
        //);
    }
    ngOnInit() {
        this.UserName = localStorage.getItem('username');
debugger;
        this.activatedroute.queryParams.subscribe(params => {
            this.ID = params['id'];
            this.isCacheUser = "";
            if(params["datacache"] != "null" || params["datacache"] == undefined){
            
            this.isCacheUser = params["datacache"] 
          /*   if(params["datacache"] == undefined){
            this.isCacheUser  = localStorage.getItem('CacheUser');
} */
localStorage.setItem('CacheUser', this.isCacheUser);

//this.UserName =  params["datacache"].split('_')[1];
            }
            this.menuservice.getmenus(this.ID, this.UserName, this.isCacheUser)
                .subscribe(data => {
                    this.SubMenu = data;
                    console.log('PageName',this.SubMenu[0].MenuName);
                    this.events.publish('PageName', this.SubMenu[0].MenuName);
                    //this.MenuID = this.SubMenu[0].ID;
                });

        });
  }

  open(url:string,ID:number,MenuName:string, datacacheID:string)
  {console.log("SUBMENU",url,ID)
      localStorage.setItem("MenuName",MenuName);
      //this.router.navigateByUrl('id='+ID + '&datacache='+ datacacheID);
      this.router.navigate(['menu','first','tabs',ID]);
  }

}

