import { Component, OnInit } from '@angular/core';
import { Events } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.page.html',
  styleUrls: ['./header.page.scss'],
})
export class HeaderPage implements OnInit {
    name: string;
    constructor(public events: Events, private router: Router,
        private authentication: AuthenticationService,private _location:Location) {
        events.subscribe('PageName', (name) => {
            // user and time are the same arguments passed in `events.publish(user, time)`
            console.log('name', name);
            this.name = name;
        });
    }

    ngOnInit() {

    }
    GoToProfile() {
        alert('profile');
        this.router.navigate(['profile']);
    }

    logout() {
        this.authentication.logout();
    }
    home()
    {
        window.location.href="/menu/first";
    }
    back()
    {
            this._location.back();
    }
}
