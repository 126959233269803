import { Component, OnInit } from '@angular/core';
import { MenuService } from '../Services/menu.service';
import { Router, RouterEvent } from '@angular/router';
import { Data } from "../Services/data.service";
import { Events } from '@ionic/angular';

import {MenuItem} from 'primeng/api';
//import { EncrDecrServiceService } from '../Services/encr-decr-service.service';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {
    MenuData: any;
    UserName: string;
    items: MenuItem[];
    constructor(private menuservice: MenuService, private router: Router,
        private data: Data, public events: Events
    ) {
        this.events.publish('PageName', '');
       // alert(JSON.stringify(this.data.storage));
      
    }

    ngOnInit() {
        var encrypted =  localStorage.getItem('username');
       // var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', encrypted);
         this.UserName = encrypted;
         


        //this.UserName = localStorage.getItem('username');
        console.log(this.UserName);
        debugger;
        //this.UserName = 'Admin';
        if(this.UserName == 'SanjayP' ||this.UserName=='DarshanP_EM'){
        this.menuservice.getUserRegionMapping(this.UserName).subscribe(data => {
            console.log("api", data);
            this.MenuData = data;
            console.log("Menudata1", this.MenuData);
        });
        }
        else{
            this.menuservice.getSubGroup(this.UserName).subscribe(data => {
                console.log("api", data);
                this.MenuData = data;
                console.log("Menudata1", this.MenuData);
            });
            }

        }
    
   open(url:string,ID:number,MenuName:string, datacacheID:string)
   {
       localStorage.setItem("MenuName",MenuName);
       this.router.navigateByUrl(url+'?id='+ID + '&datacache='+ datacacheID);
   }

}
