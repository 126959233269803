import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Subject, Observable, fromEvent, merge, of } from 'rxjs';
import { MainPageService } from '../../Services/MainPage.service';
import { DataTableDirective } from 'angular-datatables';
import { NavigationExtras } from '@angular/router';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { mapTo, filter } from 'rxjs/operators';
import { Data } from "../../Services/data.service";
import { NetworkService, ConnectionStatus } from '../../Services/network.service';
import { OfflineManagerService } from '../../Services/offline-manager.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { ActivatedRoute } from '@angular/router';
import { Events } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { NgZone } from "@angular/core"; //added
import { Storage } from '@ionic/storage';//added
import { IonSlides } from '@ionic/angular';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CustomService } from '../../Services/custom-service.service';
import { FileSystemNode } from '../../../assets/data/file-system-node';
import { Table } from 'primeng/table';
import { NavigationStart, Event as NavigationEvent } from "@angular/router";
//import { MapsAPILoader, MouseEvent } from '@agm/core';
//import { GeocoderModule } from 'angular-geocoder';

interface FileObject {
        name: string,
    FileData: Blob
}
@Component({
    selector: 'app-menu38',
    templateUrl: './menu38.component.html',
    styleUrls: ['./menu38.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class Menu38Page implements OnInit {
    Sys_Menu_ID: number =38;
    @ViewChild('slides') slides: IonSlides;
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    dt_Module: any;
    Module: any;
    ModuleIDList: Array<any> = [];
    ModuleDetailIDList: Array<any> = [];

    showfilter: any;
    public searchElementRef: ElementRef;
    //public minDate: any = new Date().toISOString();
    public minDate: any = "";
    public maxDate: any = "2299-12-31";
    FileName: any;//Added
    UserName: string;
    datatableparam: any = {};
    DropDown: any = {};
    PageMenuDetails: any;
    id: any;//Added
    WizardFormValue: any = {};
    @ViewChildren(MatPaginator) paginatorList: QueryList<MatPaginator>;
    // @ViewChildren(MatPaginator) paginatorList = new QueryList<MatPaginator>();
    @ViewChildren(MatSort) sortList: QueryList<MatSort>;
    MyDataSourceArray: Array<any> = [];
    isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
    expandedElementArray: Array<any> = [];
    expandedElement: any;
    isLoadingResults = true;
    MyDataSource: any;
    displayedColumnArray: Array<any> = [];
    displayedColumns: any[] = [];
    Feedback: any;
    childColumnArray: Array<any> = [];
    childColumns: any[] = [];
    FileList: Array<File> = [];
    public show: boolean = false;
    public loading: boolean;

    //-accordions-----
    step = 0;
    showContainerEmail: boolean = false;
    //--
    //EmailwithOTP
    ValidatedEmail: boolean = false;
    VerifiedEmailId: any;

    //TreeTable
    files: FileSystemNode[] = [];
    cols: any[];
    colsdisplay: any[];
    editableCols: any[];
    totalRecords: number;

    primengTableData: any[];
    scrollableCols: any;
    frozenCols: any[];
    scrollableColsArray: any[];
    frozenColsArray: any[];
    primeNgTableArray: any[];
    frozenWidthArray: any[];
    selectedColumnsArray: any[];
    treetabledataArray: any;
    filterCols: any[];
    filterColsArray: any[];
    headerLevelOne: any[];
    headerLevelOneArray: any[];

    SubmoduleDataSourceArray: Array<any> = [];/////////////////////////////Ruchita
    SubmoduleDataSource: any;////////////////Ruchita
    displayedSubmoduleColumnArray: Array<any> = [];///////////Ruchita
    displayedSubmoduleColumns: any[] = [];///////////Ruchita


    @ViewChildren('LatLong') latlong: QueryList<ElementRef>;
    lat: number;
    long: number;
    lat_long: string;

    @ViewChild('dt') primengTable: Table;
    ModuleDataWithRule: any;
    routerEventSubscription: any;
    rowval: string;

    constructor(private mainpageservice: MainPageService,
        private customService: CustomService,
        private router: Router,
        private toastController: ToastController,//Added
        private activateRouter: ActivatedRoute,
        private zone: NgZone,//Added
        private events: Events,
        private storage: Storage,//Added
        // private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) {
        events.subscribe('navigationExtras', (name) => {
            this.show = true;

            // alert('change'+name);
            let data: any;
            data = JSON.stringify(name);
            data = '{Filter:' + data + '}';
            // alert(data);
            this.GetModulewithFilter(name);
        });
    }

    ionViewDidEnter() {
        this.routerEventSubscription = this.router.events
            .pipe(
                // The "events" stream contains all the navigation events. For this demo,
                // though, we only care about the NavigationStart event as it contains
                // information about what initiated the navigation sequence.
                filter(
                    (event: NavigationEvent) => {

                        return (event instanceof NavigationStart);

                    }
                )
            )
            .subscribe(
                (event: NavigationStart) => {
                    if (event.restoredState) {//trigger on back button
                        let filter = { "rowval": this.rowval };
                        this.GetModulewithFilter(filter);
                    }
                }
            )

    }

    public latitude(event: any, fieldName) {////////////returns location latitude 

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }
    public longitude(event: any, fieldName) {///////////////returns location longitude
        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }

    public latitudeLongitude(event: any, fieldName) {////////////////returns latitude and longitude

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        debugger;
        found.nativeElement.dispatchEvent(new Event('input'));
    }

    public getMatDate(date) {
        var date1 = new Date(date);
        return date1;
    }

    resizeColumn(event, index: number) {
        let width: number;
        width = parseInt(this.frozenWidthArray[index].substring(0, this.frozenWidthArray[index].indexOf("px")));
        width = width + event.delta;
        this.frozenWidthArray[index] = width + "px";
    }

    formatDropdownValue(value: any) {
        if (value == null || value == undefined)
            return "";
        return value.Text || value;
    }
    getDefaultValue(value1, value2) {
        let result: any;
        if (value1 != null || value1 != -1 || value2 != null || value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1) {
                    result = element;
                }
            });
            return result;
        }
        return null;

    }
    getDefaultText(value1, value2) {
        let result: any;
        if (value1 != null || value1 != -1 || value2 != null || value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1 || element.Text == value1) {
                    result = element.Text;
                }
            });
            return result;
        }
        return null;
    }
    isNumber(value: any) {
        if (value) {
            return isNaN(value);
        }
    }

    public calculatePrimengTotal(col: string, primengdata) {
        let columnName = col;
        let footerTotal: Array<any> = [];

        let data = [];
        let mymodule;
        if (primengdata.hasFilter()) {
            mymodule = primengdata.filteredValue;
        }
        else {
            mymodule = primengdata.value;
        }

        var resultArray = Object.keys(mymodule).map(function (index) {
            let data = mymodule[index];
            return data;
        });
        data = resultArray;
        footerTotal[columnName] = data.map(t => t[columnName]).reduce((acc, value) => acc + (value / 1), 0)
        if (isNaN(footerTotal[columnName]) || footerTotal[columnName] == 0) {
            footerTotal[columnName] = "";
        }

        return footerTotal[columnName];
    }

    public onSubmoduleSubmit(form: any, ID: any, LinkedMenu: any): void {
        debugger;
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.SaveSubmodules(ID, form, this.UserName).subscribe(resp => {
            console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });


    }

    public calculateTotal(col: string) {
        //debugger;
        let columnName = col;
        let footerTotal: Array<any> = [];

        let data = [];

        data = this.MyDataSourceArray[0].data;



        // this.totalShelfLife=data.map(t => t[columnName].Value).reduce((acc, value) => acc + (value/1), 0);

        footerTotal[columnName] = data.map(t => t[columnName].Value).reduce((acc, value) => acc + (value / 1), 0)
        if (isNaN(footerTotal[columnName]) || footerTotal[columnName] == 0) {
            footerTotal[columnName] = "";
        }
        //debugger;

        return footerTotal[columnName];

        //return data.map(t => t.ShelfLife).reduce((acc, value) => acc + value, 0);

        //return this.players.reduce((accum, curr) => accum + curr.goals, 0);

    }

    loadNodes(event) {
        this.loading = true;
        this.GetPaginatedTreeTable(event);

    }

    calculateRowWiseTotalPrimeNG(rowData, dtIndex) {

        var columnsForSum = this.Module[0].moduleList[dtIndex].ColumnsForSum;
        var ShowSumIn = this.Module[0].moduleList[dtIndex].GetSumIn;
        let rowWiseTotal: number = 0;
        columnsForSum.forEach(element => {
            rowWiseTotal = rowWiseTotal + parseInt(rowData[element]);
        });
        rowData[ShowSumIn] = rowWiseTotal;
    }

    calculateRowwiseTotal(rowid: any, columnlist: any, ResultColumnName) {
        debugger;
        rowid = rowid + 1;

        let result: number;
        result = 0;
        columnlist.forEach(column => {
            var columnName = column + "_zline" + rowid + "_";

            var value = (<HTMLInputElement>document.getElementById(columnName)).value;
            result = result + parseFloat(value);

        })

        ResultColumnName = "label_" + ResultColumnName + "_zline" + rowid + "_";
        //this.rowTotal=result;
        $(document.getElementById(ResultColumnName)).val(result);
        (<HTMLElement>document.getElementById(ResultColumnName)).innerHTML = "" + result;
        return result;
    }

    GetPropertyRule(pkColumn, pkColumnValue, column) {
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            return "";
        let dataWithRules = this.ModuleDataWithRule;
        let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
            let data = dataWithRules[index];
            return data;
        });

        let propertyList: string;
        propertyList = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].PropertyList;
        return propertyList;
    }

    GetModulewithFilter(filter: any) {
        this.MyDataSourceArray = [];
        this.expandedElementArray = [];
        this.frozenColsArray = [];
        this.scrollableColsArray = [];
        this.primeNgTableArray = [];
        this.frozenWidthArray = [];
        this.selectedColumnsArray = [];
        this.treetabledataArray = [];
        this.filterColsArray = [];
        this.headerLevelOneArray = [];


        filter = JSON.stringify(filter);
        this.show = true;
        this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            //console.log("Moduleapi", data);
            this.Module = data;
            //console.log(data);
            this.MyDataSource = new MatTableDataSource();
            const rows = [];
            let count: number = 0;
            //res.forEach(element => rows.push(element, { detailRow: true, element }));
            ////console.log(rows);
            this.Module[0].moduleList.forEach((val) => {
                let headerLevels: number;
                headerLevels = val.HeaderLevels;
                if(val.DisplayType == 'Form'){
                    val.moduleDetails.map(d => {
                        if(d.DataType=='date' || d.DataType== 'datetime'){
                            d.value = new Date(d.value);
                        }
                    });
                }
                if (val.DisplayType == 'PrimeNgPivotTable' || val.DisplayType == 'PrimeNgTable' || val.DisplayType == 'PrimeUnfrozenTable' || val.DisplayType == '') {

                    this.frozenCols = [];
                    this.scrollableCols = [];
                    this.childColumns = [];
                    this.displayedColumns = [];
                    this.filterCols = [];
                    this.headerLevelOne = [];

                    let headerLevels: number;
                    headerLevels = val.HeaderLevels;
                    if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                        val.moduleDetails.forEach((column) => {
                            ///frozen and scrollable columns primeng
                            if (column.InputControls != "HiddenField" && column.HeaderLevel != 1) {
                                if (column.FrozenCols == true) {
                                    this.frozenCols.push(column);
                                }
                                else {
                                    this.scrollableCols.push(column);
                                }
                            }
                            else if (column.InputControls != "HiddenField" && column.HeaderLevel == 1) {
                                this.headerLevelOne.push(column);
                            }
                            this.filterCols.push(column.ColumnName);

                        });

                        /*type cast date data*/
                        var dateColumns = [];
                        dateColumns = val.moduleDetails.filter(md => md.DataType.includes("date"));
                        val.moduleData.map(d => {
                            dateColumns.forEach(column => {
                                if (d[column.ColumnName] != null)
                                    d[column.ColumnName] = new Date(d[column.ColumnName]);
                            })
                        });

                        this.ModuleDataWithRule = val.ModuleDataWithRule;
                        this.primengTableData = val.moduleData;

                        this.frozenColsArray.push(this.frozenCols);
                        this.scrollableColsArray.push(this.scrollableCols);
                        this.primeNgTableArray.push(this.primengTableData);
                        this.selectedColumnsArray.push(this.scrollableCols);
                        this.filterColsArray.push(this.filterCols);
                        this.headerLevelOneArray.push(this.headerLevelOne);

                        //this.selectedColumnsArray.push(this.scrollableCols.filter((c,index) => index < 10));

                        let frozenWidth = this.frozenCols.length * 100 + 50 + "px";
                        this.frozenWidthArray.push(frozenWidth);
                    }
                }
                else if (val.DisplayType == "TreeTable") {
                    this.frozenCols = [];
                    this.scrollableCols = [];
                    if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                        val.moduleDetails.forEach((column) => {
                            ///frozen and scrollable columns primeng
                            if (column.InputControls != "HiddenField") {

                                if (column.FrozenCols == true) {
                                    this.frozenCols.push(column);
                                }
                                else {
                                    this.scrollableCols.push(column);
                                }
                            }

                        });


                        var treedata = [];
                        treedata = val["TreeOutputDataRoot"];
                        let file: FileSystemNode[] = [];

                        for (let i = 0; i < (<any>treedata).data.length; i++) {

                            let rootObj = (<any>treedata).data[i];
                            let node = new FileSystemNode(rootObj, null, val.moduleDetails);
                            file.push(node);
                        }
                        this.files = file;
                        this.scrollableColsArray.push(this.scrollableCols);
                        this.frozenColsArray.push(this.frozenCols);
                        this.treetabledataArray.push(this.files);

                        this.loading = false;
                    }
                }
                else if (val.DisplayType == 'FormTableSubmodule') {
                    this.displayedSubmoduleColumns = [];

                    val.submodule.forEach(submodule => {
                        this.SubmoduleDataSourceArray = [];
                        if (submodule.DisplayType == "" || submodule.DisplayType == null) {
                            this.displayedColumns = [];
                            this.SubmoduleDataSource = new MatTableDataSource();

                            let dataArray: Array<any> = [];
                            dataArray = submodule.ModuleDataWithRule;
                            var resultArray = Object.keys(dataArray).map(function (index) {
                                let data = dataArray[index];

                                return data;
                            });


                            this.SubmoduleDataSource.data = resultArray;

                            submodule.subModuleDetails.forEach((submoduledetail) => {
                                if (submoduledetail.ChildColumn == false && (submoduledetail.HeaderLevel == 0 || submoduledetail.HeaderLevel == headerLevels)) {
                                    if (!this.ModuleDetailIDList.includes(submoduledetail.ID)) {
                                        this.displayedSubmoduleColumns.push((submoduledetail.ColumnName));
                                    }
                                }
                                else {
                                    this.childColumns.push((submoduledetail.ColumnName));
                                }
                            })

                            this.displayedSubmoduleColumnArray.push(this.displayedSubmoduleColumns);

                            this.SubmoduleDataSourceArray.push(this.SubmoduleDataSource);
                            console.log("submoduledata", this.SubmoduleDataSourceArray)
                            //pagination
                            let paginatorArrya = this.paginatorList.toArray();
                            //let sortarray = this.sortList.toArray();
                            let count: number = 0;
                            this.SubmoduleDataSourceArray.forEach((item) => {
                                ////debugger;
                                item.paginator = paginatorArrya[count];
                                //item.sort = sortarray[count];
                                count++;
                            })

                            this.isLoadingResults = false;
                            count++;
                        }
                        if (submodule.DisplayType == 'PrimeNgPivotTable' || submodule.DisplayType == 'PrimeNgTable' || submodule.DisplayType == 'PrimeUnfrozenTable' || submodule.DisplayType == '') {

                            this.frozenCols = [];
                            this.scrollableCols = [];
                            this.childColumns = [];
                            this.displayedColumns = [];
                            this.filterCols = [];
                            this.headerLevelOne = [];

                            let headerLevels: number;
                            headerLevels = val.HeaderLevels;
                            if (submodule.subModuleDetails != null && typeof (submodule.subModuleDetails) != undefined) {
                                submodule.subModuleDetails.forEach((column) => {
                                    ///frozen and scrollable columns primeng
                                    if (column.InputControls != "HiddenField" && column.HeaderLevel != 1) {
                                        if (column.FrozenCols == true) {
                                            this.frozenCols.push(column);
                                        }
                                        else {
                                            this.scrollableCols.push(column);
                                        }
                                    }
                                    else if (column.InputControls != "HiddenField" && column.HeaderLevel == 1) {
                                        this.headerLevelOne.push(column);
                                    }
                                    this.filterCols.push(column.ColumnName);

                                });

                                /*type cast date data*/
                                var dateColumns = [];
                                dateColumns = submodule.subModuleDetails.filter(md => md.DataType.includes("date"));
                                submodule.moduleData.map(d => {
                                    dateColumns.forEach(column => {
                                        if (d[column.ColumnName] != null)
                                            d[column.ColumnName] = new Date(d[column.ColumnName]);
                                    })
                                });

                                this.ModuleDataWithRule = val.ModuleDataWithRule;
                                this.primengTableData = submodule.moduleData;
                                this.frozenColsArray.push(this.frozenCols);
                                this.scrollableColsArray.push(this.scrollableCols);
                                this.primeNgTableArray.push(this.primengTableData);
                                this.selectedColumnsArray.push(this.scrollableCols);
                                this.filterColsArray.push(this.filterCols);
                                this.headerLevelOneArray.push(this.headerLevelOne);

                                //this.selectedColumnsArray.push(this.scrollableCols.filter((c,index) => index < 10));

                                let frozenWidth = this.frozenCols.length * 100 + 50 + "px";
                                this.frozenWidthArray.push(frozenWidth);
                            }
                        }

                    });

                }
                else if (val.DisplayType === 'Report') {
                    this.displayedColumns = [];
                    this.MyDataSource = new MatTableDataSource();
                    this.MyDataSource.data = val.moduleData;
                    //console.log('Report', val.moduleData)
                    this.MyDataSource.sortingDataAccessor = this.sortingDataAccessor;
                    this.MyDataSource.sort = this.sort;
                    this.MyDataSourceArray.push(this.MyDataSource);
                    debugger;
                    for (const key of Object.keys(val.moduleData[0])) {
                        this.displayedColumns.push((key));
                    }
                    this.displayedColumnArray.push(this.displayedColumns);
                    //pagination
                    let paginatorArrya = this.paginatorList.toArray();
                    //let sortarray = this.sortList.toArray();
                    let count: number = 0;
                    this.MyDataSourceArray.forEach((item) => {
                        //debugger;
                        item.paginator = paginatorArrya[count];
                        //item.sort = sortarray[count];
                        count++;
                    })

                    this.isLoadingResults = false;
                    count++;

                }
                //else if (val.DisplayType === '' || val.DisplayType === 'PivotTable'||val.DisplayType==='MultiHeaderTable') {
                //    this.displayedColumns = [];
                //    this.MyDataSource = new MatTableDataSource();

                //    let dataArray: Array<any> = [];
                //    dataArray = val.ModuleDataWithRule;
                //    var resultArray = Object.keys(dataArray).map(function (index) {
                //        let data = dataArray[index];

                //        return data;
                //    });


                //    this.MyDataSource.filterPredicate = (data, filter: string) => {
                //        const accumulator = (currentTerm, key) => {
                //            return this.nestedFilterCheck(currentTerm, data, key);
                //        };
                //        const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
                //        // Transform the filter by converting it to lowercase and removing whitespace.
                //        const transformedFilter = filter.trim().toLowerCase();
                //        return dataStr.indexOf(transformedFilter) !== -1;
                //    };

                //    this.MyDataSource.sortingDataAccessor = this.sortingDataAccessor;
                //    this.MyDataSource.sort = this.sort;
                //    this.MyDataSource.data = resultArray;

                //    val.moduleDetails.forEach((val) => {
                //        if (val.ChildColumn == false && (val.HeaderLevel == 0 || val.HeaderLevel == headerLevels)) {
                //            if (!this.ModuleDetailIDList.includes(val.ID)) {
                //                this.displayedColumns.push((val.ColumnName));
                //            }
                //        }
                //        else {
                //            this.childColumns.push((val.ColumnName));
                //        }
                //    })

                //    this.displayedColumnArray.push(this.displayedColumns);
                //    this.childColumnArray.push(this.childColumns);
                //    let childColumns = this.childColumns;


                //    var filtered = resultArray.map(function (result) {

                //        let rows: Array<any> = [];
                //        rows = result;
                //        var rowArray = childColumns.map(function (index) {
                //            let key = Object.keys(rows);

                //            let data = {
                //                ["" + index]: rows[index],
                //            }
                //            //console.log(data);
                //            return data;

                //        });



                //        let rowsvalue: any = {};
                //        rowsvalue = Object.assign({}, ...rowArray);

                //        return rowsvalue;
                //    })


                //    this.expandedElement = filtered;
                //    this.expandedElementArray.push(this.expandedElement);

                //    //val.moduleData.forEach(element => rows.push(element, { detailRow: true, element }));
                //    //    //console.log(rows);
                //    this.MyDataSourceArray.push(this.MyDataSource);

                //    //pagination
                //    let paginatorArrya = this.paginatorList.toArray();
                //    //let sortarray = this.sortList.toArray();
                //    let count: number = 0;
                //    this.MyDataSourceArray.forEach((item) => {
                //        //debugger;
                //        item.paginator = paginatorArrya[count];
                //        //item.sort = sortarray[count];
                //        count++;
                //    })

                //    this.isLoadingResults = false;
                //    count++;
                //}
            });
            this.show = false;
        });
    }

    GetPaginatedTreeTable(filter: any) {
        this.MyDataSourceArray = [];
        this.expandedElementArray = [];
        filter = JSON.stringify(filter);
        this.show = true;

        this.mainpageservice.GetPaginatedTreeTable(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            console.log("Moduleapi", data);
            this.Module = data;
            var columns = [];
            columns = data["moduleDetails"];
            var treedata = [];
            treedata = data["TreeOutputDataRoot"];
            var moduleDetails = data["moduleDetails"];
            var temp = [];
            if (moduleDetails != null && typeof (moduleDetails) != undefined) {
                moduleDetails.forEach((column) => {
                    if (column.InputControls != 'HiddenField')
                        temp.push(column);
                });
            }
            this.cols = temp;

            let file: FileSystemNode[] = [];

            for (let i = 0; i < (<any>treedata).data.length; i++) {

                let rootObj = (<any>treedata).data[i];
                let node = new FileSystemNode(rootObj, null, columns);
                file.push(node);
            }
            this.files = file;
            console.log("treedata", this.files);
        });
    }

    @ViewChild(MatSort) sort: MatSort;
    sortingDataAccessor(item, property) {

        if (property.includes('.')) {
            return property.split('.')
                .reduce((object, key) => object[key], item);
        }
        return item[property].Value;
    }//sorting nested object array

    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }//searching nested object array


    ngOnInit() {
        this.show = true;
        this.loading = true;
        let filter1 = localStorage.getItem('navigationExtras');
        // this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        // ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
        //     this.GetModulewithFilter(filter1);
        // });

        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.rowval = this.activateRouter.snapshot.queryParamMap.get('rowval');
            }
        });

        this.UserName = localStorage.getItem('username');
        this.dtOptions = this.mainpageservice.buildDtOptions();
        this.mainpageservice.getPageMenuDetails(this.Sys_Menu_ID, this.UserName).subscribe(filter => {
            //console.log('filter', filter);
            this.PageMenuDetails = filter;
            this.show = false;

        });

        this.mainpageservice.getModulesToShow(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            debugger;
            ////console.log("ModuleData", data);
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleIDList.push(dataArray)
            }

        })
        //To get ModuledetailIDTo hide
        this.mainpageservice.getModuleDetailIdToHide(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            debugger;
            ////console.log("ModuleData", data);
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleDetailIDList.push(dataArray)
            }

        })


        this.GetModulewithFilter(filter1);
        this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {

            //console.log("Moduleapi", data);

            this.Feedback = data;

        });

    }


    DropdownChange(ModuleDetailId, event, index) {

        if (event.value.Value != '') {
            this.mainpageservice.getDependentDropDownValue(ModuleDetailId, event.value.Value).subscribe(response => {

                //console.log(response);
                this.DropDown[index] = <any>(response)["Table"];
            }, err => {

            });
        }

    }

    //susan added - start
    GetDependentDropDown(ParameterId, event, index) {
        debugger;
        var IDvalue = event.value.Value == undefined ? event.value.Value : event.value.Value;
        //var IDvalue = event.detail.value.Value == undefined ? event.detail.value.Value : event.detail.value.Value;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.getDependentDropDownValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var DropDown = document.getElementsByName(index)
            this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            //console.log(err);
        });
    }
    //susan added - end

    GetDependentDropDownThroughValue(ParameterId, event, index) {
        //var IDvalue = event.detail.value.Text == undefined ? event.detail.value.Text : event.detail.value.Text;
        var IDvalue = event.value.Text == undefined ? event.value.Text : event.value.Text;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.GetDependentDropDownThroughValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var DropDown = document.getElementsByName(index)
            this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            ////console.log(err);
        });
    }


    navigateOnFormSubmit(LinkedMenu) {
        let filter = localStorage.getItem("navigationExtras");
        this.events.publish('navigationExtras', JSON.parse(filter));
        let navigationExtras: NavigationExtras = {
            queryParams: JSON.parse(filter)

        };
        this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);

    }

    //Button config save for form
    UpdateData(form: any, ID: any, ButtonId: any, LinkedMenu: any) {
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.UpdateData(ID, ButtonId, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }
    //Button config save for table/pivottable
    UpdateDatatable(form: any, ID: any, ButtonId: any, LinkedMenu: any) {
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.UpdateDatatable(ID, ButtonId, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }


    public GetPivotTableModule(ID: number, dt: any, filter: any, dtIndex: number) {
        this.mainpageservice.GetPivotTableModule(this.Sys_Menu_ID, ID, this.UserName, filter).subscribe(res => {
            var val = res;
            if (val["moduleDetails"] != null && typeof (val["moduleDetails"]) != undefined) {
                this.primengTableData = val["moduleData"];
                this.primeNgTableArray[dtIndex] = this.primengTableData;
            }


            if (dt.hasFilter()) {
                console.log(dt.filters);
                this.primengTable.filters = dt.filters
            }

        });

    }

    SubmitPrimeNgTable(ID: any, filter: any, dt: any, index: number) {
        debugger;

        this.show = true;
        /*type cast date to string*/
        var dateColumns = [];
        dateColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType.includes("date"));
        dt.value.map(d => {
            dateColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                }
                //        if (d[column.ColumnName] != null) {

                //            d[column.ColumnName] = d[column.ColumnName].toISOString();
                //        }
            })
        });




        //var dropdowns = [];
        //dropdowns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.InputControls.includes("DropDownList"));
        //dt.value.map(d => {
        //    dropdowns.forEach(column => {
        //        if (d[column.ColumnName] != null) {
        //            if (d[column.ColumnName].Text != undefined)
        //                d[column.ColumnName] = d[column.ColumnName].Text;
        //            else
        //                d[column.ColumnName] = d[column.ColumnName];
        //        }
        //    })
        //})

        filter = JSON.stringify(filter);
        var data = {};
        if (dt.hasFilter()) {
            data["data"] = dt.filteredValue.slice(0, parseInt(dt.rows) + parseInt(dt.first))
        }
        else {
            data["data"] = dt.value.slice(0, parseInt(dt.rows) + parseInt(dt.first));
        }
        data["filter"] = filter;

        this.mainpageservice.SavePrimeNgTable1(ID, data, this.UserName, '{Filter:' + filter + '}').subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetPivotTableModule(ID, dt, filter, index);

            toast.then(toast => toast.present());
            this.show = false;
        });
    }

    //To submit Form
    onSubmit(form: any, ID: any, LinkedMenu: any): void {
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, this.UserName).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });


    }
    GetFileSelected(files: Array<File>) {
        debugger;
        this.FileList = files;

    }
    //For Saving The FeedBack of the ccustomers
    onSubmitFeedback(form: any): void {

        const that = this;

        let Mess = undefined;

        this.UserName = localStorage.getItem('username');

        this.show = true;

        //Form Without FileUpload

        this.mainpageservice.SaveFeedback(this.Sys_Menu_ID, form, this.UserName).subscribe(resp => {

            //console.log(JSON.stringify(resp["Message"]));

            let toast = this.toastController.create({

                message: resp["Message"],

                duration: 3000,

                position: 'bottom',

                closeButtonText: 'Ok',

            });

            toast.then(toast => toast.present());

            this.show = false;

            this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {

                //console.log("Moduleapi", data);

                this.Feedback = data;

                this.show = false;

                //debugger;

                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {

                    // Destroy the table first

                    // Call the dtTrigger to rerender again

                    dtInstance.destroy();

                    this.dtTrigger.next();

                });

            });

        });

    }

    Savedatatable(form: any, ID: any): void {

        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let filter = localStorage.getItem('navigationExtras');

        //Form Without FileUpload
        this.mainpageservice.SaveDatatable(ID, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter);
            toast.then(toast => toast.present());
            this.show = false;

        });

        //Form With FileUpload
        //this.storage.get('FileType-' + this.id).then(FileType => {

        //    this.mainpageservice.Savedata1(ID, JSON.stringify(form), this.UserName, FileType).subscribe(resp => {

        //        //console.log(JSON.stringify(resp["Message"]));
        //        let toast = this.toastController.create({
        //    message: resp["Message"],
        //    duration: 3000,
        //    position: 'bottom',
        //            showCloseButton: true,
        //    closeButtonText: 'Ok',

        //        });
        //        this.show = false;
        //        if (JSON.stringify(resp["Message"]) == "Record saved successfully!!")
        //            this.storage.clear();
        //        toast.then(toast => toast.present());

        //    });
        //});
    }

    onSubmitFilter(formdata: any): void {
        this.MyDataSourceArray = [];
        this.displayedColumnArray = [];


        //console.log('you submitted value in Filter:', formdata);
        let data: any;
        data = JSON.stringify(formdata);
        data = '{Filter:' + data + '}';
        //console.log(data);
        this.UserName = localStorage.getItem('username');
        sessionStorage.setItem('filterdata', data);
        this.datatableparam = sessionStorage.getItem('datatableparameter');
        //console.log(this.datatableparam);
        this.GetModulewithFilter(formdata);
        this.showfilter = false;

    }


    downloadreport(): void {
        //alert(menuid);
        this.show = true;

        let data: any;
        this.UserName = localStorage.getItem('username');
        data = sessionStorage.getItem('filterdata');
        this.mainpageservice.exporttoexcel(this.Sys_Menu_ID, this.UserName, data).subscribe(resp => {
            //console.log(resp);

            //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            var blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var filename = 'download_report.xlsx';

            saveAs(blob, filename);

        })
        this.show = false;

    }

    navigate(i, LinkedMenu) {

        let qm = { "rowval": i };

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "rowval": i
            }

        };
        localStorage.setItem('navigationExtras', JSON.stringify(qm));
        this.events.publish('navigationExtras', qm);
        this.router.navigate(["/menu/first/tabs/" + LinkedMenu], navigationExtras);
        // this.router.navigate(["/menu/first/tabs/GotoForm"], navigationExtras);

    }

    ngAfterViewInit(): void {

        this.dtTrigger.next();
        let paginatorArrya = this.paginatorList.toArray();
        let sortarray = this.sortList.toArray();
        let count: number = 0;
        this.MyDataSourceArray.forEach((item) => {
            debugger;
            item.paginator = paginatorArrya[count];
            item.sort = sortarray[count];
            count++;
        })


        // this.slides.lockSwipes(true);
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        this.routerEventSubscription.unsubscribe();
    }

    ionViewDidLeave(): void {

        this.events.unsubscribe('navigationExtras');
    }
    onWizardSubmit(ID: any): void {
        debugger;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let form = JSON.stringify(this.WizardFormValue)
        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, null).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        });

    }

    Next(ID: any, wizardFormdata: any) {
        debugger;
        this.WizardFormValue = Object.assign(this.WizardFormValue, wizardFormdata);
        this.slides.lockSwipes(false);
        this.slides.slideNext();
        this.slides.lockSwipes(true);
    }

    Prev() {
        this.slides.lockSwipes(false);
        this.slides.slidePrev();
        this.slides.lockSwipes(true);
    }
    /////////////////Convert Image to blob////////////////////


    private base64textString: String = "";
    //handleFileInput(e) {
    //    var files = e.target.files;
    //    var file = files[0];

    //    if (files && file) {
    //        var reader = new FileReader();

    //        reader.onload = this._handleReaderLoaded.bind(this);

    //        reader.readAsBinaryString(file);
    //    }
    //}
    fileToUpload: File = null;
    handleFileInput(files: FileList) {
        this.UserName = localStorage.getItem('username');
        this.fileToUpload = files.item(0);
        //To display loader
        this.show = true;
        this.mainpageservice.postFile(this.fileToUpload, this.UserName, 'FileUpload').subscribe(resp => {
            this.show = false;
            //console.log(JSON.stringify(resp["Message"]));
            //To display loader
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok'
            });
            toast.then(toast => toast.present());
        });
    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.id = Math.random();
        sessionStorage.setItem('id', this.id);
        this.base64textString = btoa(binaryString);
        //console.log(btoa(binaryString));
        this.storage.set('sampler-' + this.id, btoa(binaryString));
        let FileType: FileObject = {
            name: this.FileName,
            FileData: this.b64toBlob(btoa(binaryString), '', '')
        };
        //console.log(btoa(binaryString));

        this.storage.set('FileType-' + this.id, FileType);
        let toast = this.toastController.create({
            message: "Your image is stored. Click on Submit to save the form.",
            duration: 3000,
            position: 'bottom',
            showCloseButton: true,
            closeButtonText: 'Ok',

        });
        toast.then(toast => toast.present());


        //console.log('blob data while passing to savedata1' + JSON.stringify(FileType));


    }

    replacer(key, val) {
        if (key == "_parent" || key == "parent") return undefined;
        else return val;

    }


    SubmitTreeTable(ID: any) {

        //console.log("treedataObject",this.files);
        var dataObject = JSON.stringify(this.files, this.replacer);

        console.log("JSONtreedataObject", JSON.stringify(this.files, this.replacer));

        this.mainpageservice.SaveTreetable(ID, dataObject, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            debugger;
            this.GetPaginatedTreeTable(filter);
            toast.then(toast => toast.present());
            this.show = false;

        });
    }


    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    RenderDataTable() {
        this.show = true;
        this.UserName = localStorage.getItem('username');
        this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, undefined)
            .subscribe(
                res => {

                    debugger;
                    this.MyDataSource = new MatTableDataSource();
                    this.Module = res;
                    // const rows = [];
                    let count: number = 0;
                    //res.forEach(element => rows.push(element, { detailRow: true, element }));
                    ////console.log(rows);
                    this.Module[0].moduleList.forEach((val) => {
                        //if (val.DisplayType === '') {
                        this.MyDataSource = new MatTableDataSource();

                        this.MyDataSource.data = val.moduleData;
                        //val.moduleData.forEach(element => rows.push(element, { detailRow: true, element }));
                        //    //console.log(rows);
                        this.MyDataSourceArray.push(this.MyDataSource);
                        this.isLoadingResults = false;
                        count++;
                        //}
                        //else {
                        //    alert(val.DisplayType);
                        //}
                    })

                },
                error => {
                    //console.log('There was an error while retrieving Posts !!!' + error);
                });
    }

    applyFilterForTable(filterValue: string, index: number) {
        this.MyDataSourceArray[index].filter = filterValue.trim().toLowerCase();

        if (this.MyDataSourceArray[index].paginator) {
            this.MyDataSourceArray[index].paginator.firstPage();
        }
    }


    doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let data: any
                this.UserName = localStorage.getItem('username');
                data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


                this.mainpageservice.GenerateReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

                    //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                    var blob = new Blob([resp], { type: 'application/pdf' });
                    var d = new Date();
                    var montharr = d.getMonth() + 1;
                    var month = (montharr < 10 ? '0' : '') + montharr;
                    var day = ((d.getDate()) < 10 ? '0' : '') + (d.getDate());
                    var year = d.getFullYear();
                    var x = year + '-' + month + '-' + day;
                    var filename = ReportType + x + '.pdf';

                    saveAs(blob, filename);
                    this.show = false;
                });
                resolve();
            }, 1000);
        });

        debugger;
        return promise;
    }

    GenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        if (Rowval == '' || typeof (Rowval) === undefined || Rowval == null) {
            let filter = localStorage.getItem("navigationExtras");
            let param = JSON.parse(filter);
            Rowval = param["rowval"];
        }


        this.doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType).then(
            () => this.show = true,
            // () => //console.log("Task Errored!"),
        );

    }
    SendReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let data: any
        this.UserName = localStorage.getItem('username');
        data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


        this.mainpageservice.SendReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

            let toast = this.toastController.create({
                message: "Mail sent successfully!",
                duration: 3000,
                position: 'bottom',
                closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        })
    }
    toggleOTPEmail(email: any) {
        this.VerifiedEmailId = email;
        this.showContainerEmail = true;
        this.mainpageservice.GenerateOTPEmail(email).subscribe(resp => {
            alert(JSON.stringify(resp));
            let OTP = resp["OTP"];
            localStorage.setItem('OTP', OTP);

        });
    }
    CheckOTP(OTP: any) {
        let OTPValue = localStorage.getItem('OTP');
        if (OTP == OTPValue) {
            alert("Email validated");
            localStorage.removeItem('OTP');
            this.showContainerEmail = false;
            this.ValidatedEmail = true;
        }
        else {
            alert("OTP not valid");
        }
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

}
