import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Headers, RequestOptions, ResponseContentType } from "@angular/http";
import { fromEvent, merge, of, from, throwError } from 'rxjs';
import { mapTo, catchError } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
//import { ServerSideColumn } from '../Models/ServersideColumn';
//import { Module } from '../Models/Module';
//import { Observable } from 'rxjs/Observable';


const apiUrl = environment.apiUrl;

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Injectable(
    {
        providedIn: 'root'
    })
export class MainPageService {
    online$: Observable<boolean>;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    filterData: '"Filter":{"Entity":"2"}';
    columnHeader: any;
    dtOptions: DataTables.Settings = {};
    onlineFlag: any;
    Module: any;
    constructor(private _http: HttpClient) {
        this.onlineFlag = navigator.onLine;
        console.log(this.onlineFlag);
    }

    //getListModule(MenuID, UserName): Observable<Module[]> {
    //    return this._http.get<Module[]>(`${apiUrl}/${'api/Page/GetListModule?UserName=' + UserName + '&MenuNo=' + MenuID}`);
    //}
    getmodules(MenuID, UserName) {
        //alert('getmodules');
        //console.log(apiUrl);
        return this._http.get(`${apiUrl}/${'api/Page/GetModules?Id=' + MenuID + '&UserName=' + UserName}`);
    }
    getdata() {
        return this._http.get(`${'https://jsonplaceholder.typicode.com/photos'}`);
    }
    //GetColumnsForDataTable(MenuID, UserName): Observable<ServerSideColumn[]> {

    //    return this._http.get<ServerSideColumn[]>(`${apiUrl}/${'api/Page/GetColumnsForDataTable_AG_6?Id=' + MenuID + '&UserName=' + UserName}`, { responseType: 'json' });

    //}



    getPageMenuDetails(MenuID, UserName) {
        //alert('call');
        return this._http.get(`${apiUrl}/${'api/Page/GetPageMenuDetail?UserName=' + UserName + '&MenuNo=' + MenuID}`);
    }
    GetPaginatedData(MenuID, UserName, dataTablesParameters, data: any): Observable<DataTablesResponse> {
        //alert(dataTablesParameters);
        data = sessionStorage.getItem('filterdata');
        //alert("You are in main pageservice paginated" +data);

        console.log('dataTablesParameters', dataTablesParameters);
        console.log('GetPaginatedData');

        const body = new HttpParams()
            .set('datatable', JSON.stringify(dataTablesParameters))
            .set('filterData', data);
        ResponseContentType.ArrayBuffer
        return this._http.post<DataTablesResponse>(`${apiUrl}/${'api/Page/GetPaginatedData1?Id=' + MenuID +
            '&UserName=' + UserName}`,
            body.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            }
        );

        //return this._http.post<DataTablesResponse>(`${apiUrl}/${'api/Page/GetPaginatedData1?Id=' + MenuID + 
        //'&UserName=' + UserName}`, dataTablesParameters, data);
    }

    GetfilterPaginatedData(MenuID, UserName, dataTablesParameters, data: any): Observable<DataTablesResponse> {
        //alert("You are in main pageservice paginated1" +data);
        //alert(dataTablesParameters);
        sessionStorage.setItem('filterdata', data);
        console.log('dataTablesParameters', dataTablesParameters);
        console.log('GetPaginatedData');

        const body = new HttpParams()
            .set('datatable', dataTablesParameters)
            .set('filterData', data);

        return this._http.post<DataTablesResponse>(`${apiUrl}/${'api/Page/GetPaginatedData1?Id=' + MenuID +
            '&UserName=' + UserName}`,
            body.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
            }
        );

        //return this._http.post<DataTablesResponse>(`${apiUrl}/${'api/Page/GetPaginatedData1?Id=' + MenuID + 
        //'&UserName=' + UserName}`, dataTablesParameters, data);
    }

    public buildDtOptions(): DataTables.Settings {
        return {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            "language": {
                "processing": "Hang on. Waiting for response..." //add a loading image,simply putting <img src="loader.gif" /> tag.
            },
            responsive: true,
            //to restrict from ordering the data
            order: [],
            columnDefs: [{ orderable: false, targets: [0] }]
        };
    }

    Savedata(ModuleID, data, username) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules_v1?id=' + ModuleID + '&username=' + username}`, data)

    }

    SaveDatatable(ModuleID, data, username) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveDataTable?id=' + ModuleID + '&username=' + username}`, data)

    }

    SaveSubmodules(ModuleID, data, username) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveSubmodules?id=' + ModuleID + '&username=' + username}`, data)

    }

    Savedata1(ModuleID, data, username, httpdata) {

        //debugger;

        //alert("You are in Mainpage.service.ts " +username);

        var formData = new FormData();

        console.log('data :-' + data);
        console.log('File Name :- ' + httpdata.name + ' File Data :-' + httpdata.FileData);
        formData.append('data', data);
        formData.append(httpdata.name, httpdata.FileData);
        console.log(JSON.stringify(formData));
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules?id=' + ModuleID + '&username=' + username}`, formData);
    }

    Savedata1_backup(ModuleID, data, username, httpdata) {
        // alert("You are in Mainpage.service.ts " +username);
        var headers = new Headers();
        headers.append("Accept", 'application/json');
        headers.append('Content-Type', 'multipart/form-data');
        const requestOptions = new RequestOptions({ headers: headers });

        data.append('blob', httpdata);
        console.log(data);
        //data.append('blob',httpdata);
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules?id=' + ModuleID + '&username=' + username}`, data);
    }

    // return this._http.post(`${apiUrl}/${'api/Page/SaveModules?id='+ModuleID+'&username=' +username}`, data)


    postFile(fileToUpload: File, username, columnHeader) {
        const data: FormData = new FormData();
        data.append('fileKey', fileToUpload);
        // const data='{"file":"'+fileToUpload.name+'""}'
        console.log(data);
        return this._http.post(`${apiUrl}/${'api/Page/UploadJsonFile?columnHeader=' + columnHeader + '&UserName=' + username}`, data
        )/*
        .subscribe(
        data  => {
        console.log("POST Request is successful ", data);
        },
        error  => {

        console.log("Error", error);

        }

        );*/
    }

    postFile1(data: FormData, username, columnHeader) {

        return this._http.post(`${apiUrl}/${'api/Page/UploadJsonFile?columnHeader=' + columnHeader + '&UserName=' + username}`, data
        ).pipe(catchError(this.errorhandler));
    }
    public errorhandler(error: HttpErrorResponse) {
        console.log('error', error);
        return throwError(error.message);
    }

    SaveJsData(ModuleID, data, username) {

        const body = new HttpParams()

            .set('data', data);

        return this._http.post(`${apiUrl}/${'api/Page/SaveModuleJson?id=' + ModuleID + '&username=' + username}`, body, { responseType: 'text' })

    }

    // exporttoexcel(menuid, UserName, data) {
    //     debugger;
    //     //console.log("data",JSON.stringify(data));
    //     //var datasend=JSON.stringify(data);
    //     const body = new HttpParams()
    //         .set('data', data);
    //     return this._http.post(`${apiUrl}/${'api/Page/ExportToexcel?MenuID=' + menuid + '&UserName=' + UserName}`, body, { responseType: 'arraybuffer' });
    // }



    exporttoexcel(menuid, UserName, data) {
        //console.log("data",JSON.stringify(data));
        //var datasend=JSON.stringify(data);
        //const body = new HttpParams()
        //    .set('data', data);
        return this._http.post(`${apiUrl}/${'api/Page/ExportToexcel?MenuId=' + menuid + '&UserName=' + UserName}`, data, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json' } });
    }



    public buildDtOptions1(): DataTables.Settings {
        return {
            pagingType: 'full_numbers',
            pageLength: 10,
            processing: true,
            serverSide: true,
            orderCellsTop: true,
            ajax: (dataTablesParameters: any, callback) => {
                //let pageOffset = dataTablesParameters.start;
                //let pageSize = dataTablesParameters.length;
                //let searchText = dataTablesParameters.search != null ? dataTablesParameters.search.value : '';
                //alert('enter' + this.menuID);
                this.GetPaginatedData(1, 'Admin', dataTablesParameters, '')
                    //this._http.post<DataTablesResponse>('http://localhost:52371/api/Page/GetPaginatedData?Id=' + this.menuID + '&UserName=' + this.UserName, dataTablesParameters, {})
                    .subscribe(resp => {
                        this.Module = resp.data;
                        console.log('serverside', this.Module);
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: [],
                        });
                    });
            },
            responsive: true

            //scroller: true

        };
    }
    GetModulesWithFilter(MenuID, UserName, data) {
        console.log("You are in getmoduleswithfilter");
        return this._http.post(`${apiUrl}/${'api/Page/GetModulesWithFilters_v1?Id=' + MenuID + '&UserName=' + UserName}`, data, this.headers);

    }

    GetPaginatedTreeTable(MenuID, UserName, data) {
        console.log("You are in GetPaginatedTreeTable");
        return this._http.post(`${apiUrl}/${'api/Page/GetPaginatedTreeTable?Id=' + MenuID + '&UserName=' + UserName}`, data, this.headers);

    }

    SaveTreetable1(ModuleID, data1, username,index,filter) {

        var data = new FormData();
        data.append('files', data1);
        data.append('filter', filter);
        return this._http.post(`${apiUrl}/${'api/Page/SaveTreeTable1?id=' + ModuleID + '&index='+index+'&username=' + username}`, data);
    }
    SaveTreetable(ModuleID, data1, username) {

        var data = new FormData();
        data.append('files', data1);
        return this._http.post(`${apiUrl}/${'api/Page/SaveTreeTable?id=' + ModuleID +'&username=' + username}`, data);
    }

    //Get Mails
    GetMails(UserName) {
        debugger;
        return this._http.get(`${apiUrl}/` + `api/Email/GetMails?username=${UserName}`);

    }
    //Table Save Added On:May 17 2019
    SaveModule(ModuleID, data, username) {
        //const body=new HttpParams()
        //.set('data',data);
        //return this._http.post(`${apiUrl}/${'api/Page/SaveModuleJson?id=' + ModuleID + '&username=' + username}`, data, {responseType:'text', headers: { 'Content-Type': 'application/json; charset=utf-8'}  })
        return this._http.post(`${apiUrl}/${'api/Page/SaveModule?id=' + ModuleID + '&username=' + username}`, data)

    }

    GenerateReport(reporttype, username, data) {

        //const body=new HttpParams()
        //.set('data',data);

        //return this._http.post(`${apiUrl}/${'api/Page/SaveModuleJson?id=' + ModuleID + '&username=' + username}`, data, {responseType:'text', headers: { 'Content-Type': 'application/json; charset=utf-8'}  })
        //return this._http.post(`${apiUrl}/${'api/Report/ExportReport?reporttype=' + reporttype + '&username=' + username}`, data,this.headers)
        return this._http.post(`${apiUrl}/${'api/Report/ExportReport?reporttype=' + reporttype + '&username=' + username}`, data, { responseType: 'blob', headers: { 'Content-Type': 'application/json' } });
    }

    SendReport(reporttype, username, data) {
        return this._http.post(`${apiUrl}/${'api/Report/SendReport?reporttype=' + reporttype + '&username=' + username}`, data, { responseType: 'blob', headers: { 'Content-Type': 'application/json' } })
    }
    GenerateOTPEmail(Email) {
        return this._http.get(`${apiUrl}/${'api/Login/SendOTPEmail_v1?Email_Id=' + Email}`);
    }

    getDependentDropDownValue(ModuleDetailId, Id) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentDropDownValue?ModuleDetailId=' + ModuleDetailId + '&Id=' + Id}`)
    }

    GetDependentTextBoxValue(moduledetailid, Param1, Param2, username) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentTextBoxValue?ModuleDetailId=' + moduledetailid + '&Param1=' + Param1 + '&Param2=' + Param2 + '&Username=' + username}`)
    }
    GetDashboardMenu(username) {
        return this._http.get(`${apiUrl}/${'api/Menu/GetDashboardMenu?Username=' + username}`)
    }
    GetDependentDropDownThroughValue(ModuleDetailId, Id) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentDropDownThroughValue?ModuleDetailId=' + ModuleDetailId + '&Id=' + Id}`)
    }


    GetFabMenus(UserName, ParentMenuID, roleid) {

        let data: any;
        return this._http.get(`${apiUrl}/${'api/Menu/GetFabMenus?username=' + UserName + '&ParentMenuID=' + ParentMenuID + '&RoleId=' + roleid}`);

    }
    UpdateData(ModuleID: any, ButtonId: number, data: any, username: string) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveModulesButtonConfig?Id=' + ModuleID + '&ButtonId=' + ButtonId + '&UserName=' + username}`, data)
    }

    UpdateDatatable(ModuleID: any, ButtonId: number, data: any, username: string) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveDatatableButtonConfig?Id=' + ModuleID + '&ButtonId=' + ButtonId + '&UserName=' + username}`, data)
    }

    //for get the feedback data
    GetFeedback(MenuID, UserName, data) {
        debugger;
        console.log("You are in getmoduleswithfilter");
        return this._http.post(`${apiUrl}/${'api/Page/GetFeedback?Id=' + MenuID + '&UserName=' + UserName}`, data, this.headers);

    }
    //to save the feedback data
    SaveFeedback(MenuId, data, username) {
        return this._http.post(`${apiUrl}/${'api/Page/SaveFeedback?id=' + MenuId + '&username=' + username}`, data)

    }
    RunWorkflowTask(ID, username, data) {
        return this._http.post(`${apiUrl}/api/Page/RunWorkflowTask?RowValue=${ID}&UserName=${username}`, data);
    }

    getDependentFilterDropDownValue(ModuleDetailId, Id) {
        return this._http.get(`${apiUrl}/${'api/Page/GetDependentFilterDropDownValue?ParameterId=' + ModuleDetailId + '&Id=' + Id}`)
    }

    getModulesToShow(MenuID, UserName) {
        return this._http.get(`${apiUrl}/${'api/Page/GetModulesToShow?MenuID=' + MenuID + '&UserName=' + UserName}`)
    }

    getModuleDetailIdToHide(MenuID, UserName) {
        return this._http.get(`${apiUrl}/${'api/Page/GetModuleDetailIDToHide?MenuID=' + MenuID + '&UserName=' + UserName}`)
    }
    Savedata_file(fileToUpload: Array<File>, ModuleID, data, username) {
        const data1: FormData = new FormData();
        data1.append('data', JSON.stringify(data));
        fileToUpload.forEach((item) => {
            data1.append('file[]', item);
        })
        return this._http.post(`${apiUrl}/${'api/Page/SaveModules_v1?id=' + ModuleID + '&username=' + username}`,
            data1)

    }


    SavePrimeNgDatatable(ModuleID, data1, username) {
        var data = new FormData();
        data.append('files', data1);
        return this._http.post(`${apiUrl}/${'api/Page/SavePrimeNgTable?id=' + ModuleID + '&username=' + username}`, data)

    }
    SavePrimeNgTable(MenuID, ModuleID, data1, username, filter) {

        var data = new FormData();
        data.append('files', data1);
        data.append('filter', filter);
        return this._http.post(`${apiUrl}/${'api/Page/SavePrimeNgTable?id=' + ModuleID + '&username=' + username + '&MenuId=' + MenuID}`, data);
    }
    SavePrimeNgTable1(ModuleID, data, username, filter) {

        return this._http.post(`${apiUrl}/${'api/Page/SavePrimeNgTable?Id=' + ModuleID + '&UserName=' + username}`, data);
    }

    //new GetPivotTableModule
    GetPivotTableModule(ModuleID, MenuID, username, data) {
        debugger;
        return this._http.post(`${apiUrl}/${'api/Page/GetPivotTableModule?id=' + ModuleID + '&username=' + username + '&MenuId=' + MenuID}`, data);
    }

    // GetPivotTableModule(MenuId: number, ID: number, UserName: string, filter: any) {
    //     var data = {};
    //     data["Filter"] = filter;
    //     return this._http.post(`${apiUrl}/${'api/Page/GetPivotTableModule?Id=' + ID + '&MenuId=' + MenuId + '&UserName=' + UserName}`, data);
    // }

    UpdateDatatablewithfilter(ModuleID: any, ButtonId: number, data: any, filter: any, username: string) {
        var alldata = {
            "data": data,
            "filter": filter
        }
        return this._http.post(`${apiUrl}/${'api/Page/SaveDatatableButtonConfig?Id=' + ModuleID + '&ButtonId=' + ButtonId + '&UserName=' + username}`, alldata, this.headers)
    }

    GetPaginatedTreeTableATG(MenuID, UserName, ModuleID, filter, PrimeNgFilter, TableData) {
        debugger;

        var data = {};
        data["files"] = null;
        data["Filter"] = filter;
        data["PrimeNgfilter"] = PrimeNgFilter;


        console.log("You are in GetPaginatedTreeTableATG");
        return this._http.post(`${apiUrl}/${'api/Page/GetPaginatedTreeTableATG?Id=' +
            MenuID + '&UserName=' + UserName + '&ModuleID=' + ModuleID}`, data, this.headers);

    }

    SaveTreetable_v1(ID: any, data: any, UserName: string, MenuID: any) {
        debugger;
        var data = JSON.parse(data);
        return this._http.post(`${apiUrl}/${'api/Page/SaveTreeTable?Id=' + ID + '&UserName=' + UserName + '&MenuID=' + MenuID}`, data);
    }


    SavePrimeNgTableATG(MenuID, ModuleID, TableData, username, filter, PrimeNgFilter) {

        /* var data = new FormData();
        data.append('files', data1);
        data.append('filter', filter); */
        var data = {};
        data["files"] = TableData;
        data["Filter"] = filter;
        data["PrimeNgfilter"] = null;
        return this._http.post(`${apiUrl}/${'api/Page/SavePrimeNgTableATG?id=' + ModuleID +
            '&username=' + username + '&MenuId=' + MenuID}`, data);
    }

    GetPaginatedPrimeNgTableATG(MenuID, UserName, ModuleID, filter, PrimeNgFilter, TableData) {
        debugger;

        var data = {};
        data["files"] = TableData;
        data["Filter"] = filter;
        data["PrimeNgfilter"] = PrimeNgFilter;


        console.log("You are in GetPaginatedPrimeNgTableATG");
        return this._http.post(`${apiUrl}/${'api/Page/GetPaginatedPrimeNgTableATG?Id=' +
            MenuID + '&UserName=' + UserName + '&ModuleID=' + ModuleID}`, data, this.headers);

    }
    getImage(path: string) {
        return this._http.get(`${apiUrl}/${'api/Page/GetImage?path=' + path}`, { responseType: 'blob', headers: { 'Content-Type': 'image/jpeg' } });
    }
    AutoPopulateMultipleFields(value: any, auotoPopulateSP: any, UserName: string) {
        return this._http.get(`${apiUrl}/${'api/Page/AutoPopulateMultipleFields?value=' + value + '&autoPopulateSP=' + auotoPopulateSP + '&username=' + UserName}`);
    }
    MultipleDependentDropdown(moduledetailid, value, sp, username) {
        return this._http.get(`${apiUrl}/${'api/Page/GetValuesForMultipleDependantDropdown?Id=' + moduledetailid + '&value=' + value + '&DependantSP=' + sp + '&username=' + username}`);
    }
}
