import { NgModule } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { IonicModule } from '@ionic/angular';
import { routes } from './mainpage.routes';
import { IonicSelectableModule } from 'ionic-selectable';
// import { MatTableModule, MatSortModule } from '@angular/material';
// import { CdkTableModule } from '@angular/cdk/table';
//import { AgGridModule } from 'ag-grid-angular';
import { MainpagePage } from './mainpage.page';
import {CustomService} from '../Services/custom-service.service'
import { Data } from '../Services/data.service';
// import { MatPaginatorModule } from '@angular/material';
import {IonicStorageModule} from '@ionic/storage';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {
  MatTableModule, MatSortModule, MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatExpansionModule,
  MatButtonToggleModule,MatNativeDateModule,
} from '@angular/material';
//import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatRadioModule} from '@angular/material/radio';
import {MatGridListModule} from '@angular/material/grid-list';
import { CdkTableModule } from '@angular/cdk/table';
import{TreeTableModule} from 'primeng/treetable';
import {Menu4Page} from './menu4/menu4.component';
import {Menu5Page} from './menu5/menu5.component';
import {Menu8Page} from './menu8/menu8.component';

import {Menu7Page} from './menu7/menu7.component';
import {Menu9Page} from './menu9/menu9.component';
import {Menu10Page} from './menu10/menu10.component';
import {Menu11Page} from './menu11/menu11.component';
import {Menu12Page} from './menu12/menu12.component';
import {Menu13Page} from './menu13/menu13.component';
import {Menu14Page} from './menu14/menu14.component';
import {Menu15Page} from './menu15/menu15.component';
import {Menu16Page} from './menu16/menu16.component';
import {Menu17Page} from './menu17/menu17.component';
import {Menu18Page} from './menu18/menu18.component';
import {Menu20Page} from './menu20/menu20.component';
import {Menu22Page} from './menu22/menu22.component';
import {Menu21Page} from './menu21/menu21.component';
import {Menu23Page} from './menu23/menu23.component';
import {Menu24Page} from './menu24/menu24.component';
import {Menu25Page} from './menu25/menu25.component';
import {Menu26Page} from './menu26/menu26.component';
import {Menu27Page} from './menu27/menu27.component';
import {Menu28Page} from './menu28/menu28.component';
import {Menu29Page} from './menu29/menu29.component';
//import { AgmCoreModule } from '@agm/core';


import {Menu33Page} from './menu33/menu33.component';
import {Menu34Page} from './menu34/menu34.component';
import {Menu35Page} from './menu35/menu35.component';
import {Menu36Page} from './menu36/menu36.component';
import {Menu37Page} from './menu37/menu37.component';
import {Menu38Page} from './menu38/menu38.component';
import {Menu39Page} from './menu39/menu39.component';
import {Menu2Page} from './menu2/menu2.component';
import {PanelModule} from 'primeng/panel';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {CalendarModule} from 'primeng/calendar';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {ButtonModule} from 'primeng/button';
import {InputNumberModule} from 'primeng/inputnumber';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
      IonicModule,
      RouterModule.forChild(routes),
      DataTablesModule,
      IonicSelectableModule,      
      MatTableModule, MatSortModule, MatButtonModule,
      MatFormFieldModule,
      MatInputModule,
      MatPaginatorModule,
      MatRippleModule,
      MatCardModule,
      MatProgressSpinnerModule,
      MatButtonToggleModule,
      MatIconModule,
      CdkTableModule,
      MatGridListModule,
      MatRadioModule,
      ReactiveFormsModule,MatExpansionModule,
      MatStepperModule,MatDatepickerModule,MatNativeDateModule,
      TreeTableModule,
      TableModule,
      PanelModule,
      DropdownModule,MultiSelectModule,CalendarModule,InputTextModule,CheckboxModule,
      RadioButtonModule,FileUploadModule,ButtonModule,InputNumberModule,

     //MatMomentDateModule,
      IonicStorageModule.forRoot()
//,
     //AgmCoreModule.forRoot({
     //   apiKey: 'XYZ',
      //  libraries: ['places']
      //}) 
    ],
    providers: [Data,CustomService
//,
      //{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } } 
],
  
    declarations: [MainpagePage,Menu4Page,Menu5Page,Menu8Page,Menu7Page,Menu9Page,Menu10Page,Menu11Page,Menu12Page,Menu13Page,Menu14Page,Menu15Page,Menu16Page,Menu17Page,Menu18Page,Menu20Page,Menu22Page,Menu21Page,Menu23Page,
    Menu24Page,Menu25Page,Menu26Page,Menu27Page,Menu28Page,Menu29Page,Menu33Page,Menu34Page,Menu35Page,Menu36Page,Menu37Page,Menu38Page,Menu39Page,Menu2Page],
    entryComponents: []

})
export class MainpagePageModule {}
